<template>
    <div v-show="show" class="overlay"></div>
    <Form :entity_type="'In'" :entity="{
        title: 'سرمایه گذاری در طرح'
        , properties: 'R0:key=POST,value=Finance/Transaction/In;R1:key=UPDATE,value=Finance/Transaction/In'
        , options: 'R0:key=button,value=in'
    }" :center="true" @onresult="onresult" :id="id"></Form>
    <Dialog :data="{
        title: 'تفاهم نامه بیانیه ریسک',
        description: `اینجانب ${$cookies.get('display_name')} به کد یا شناسه ملی / کد فراگیر اتباع ${$cookies.get('username')} ضمن مطالعه تفاهم‌نامه، تمامی مفاد و شرایط مندرج را می‌پذیرم.`,
        extras: { title: 'مطالعه تفاهم نامه', entity: { title: 'تفاهم نامه بیانیه ریسک', description: description, default: 'open' } },
        button: { key: 'submit', name: 'می‌پذیرم', style: JSON.stringify({ 'width': '30%' }) }, type: 'text'
    }" @onclick="onclick" :class="{ 'show': show }"></Dialog>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import Form from '../components/Form.vue'
import Dialog from '@/components/Dialog.vue'

export default {
    name: 'In',
    props: ['id'],
    inject: ['base'],
    components: { Form, Dialog },
    created() {

    },
    data() {
        return {
            show: true,
            description: `«تامین مالی جمعی» به عنوا ن روشی برای سرمایه گذاری، مشابه سایر روشها، متضمن ریسک هایی برای تامین کننده است سکوی تامین مالی آتیه ایرانیان با نماد اختصاری آتیا کراد به عنوان عامل مجاز و دارای مجوز از فرابورس ایران و ثبت شده نزد اداره ثبت شرکتها و موسسات تجاری استان تهران به شماره ثبتی 570651 ، برخی از خطرات این سرمایه گذاری را در اجرای مواد ۲۹ و ۳۰  «دستورالعمل تامین مالی جمعی»، به شرح زیر به اطلاع تامین کنندگان محترم میرساند:  
در تامین مالی جمعی، متقاضی با استفاده از وجوه، اقدام به راه اندازی و یا توسعه کسب وکار اقتصادی برای تحصیل سود می نماید اما سودآوری طرح، قطعی نیست. علاوه بر این از آنجا که متقاضیان و تامین کنندگان مستند به ماده ۲ «دستورالعمل تامین مالی  جمعی» در سود و زیان طر ح ها شریک هستند، در صورت شکست یا انحراف طرح، احتمال ضرر یا از بین رفتن سرمایه تامین کننده، وجود دارد. ضمناً در صورت انحراف برنامه کسب وکار متقاضی از برنامه پیش بینی شده و نکول متقاضی در پرداخت، عامل در راستای حفظ منافع تامینکنندگان و در چارچوب قراردادهای مربوط، اقدامات مقتضی ( از جمله اخذ وثایق و تضامین متناسب) را انجام می دهد . 
با توجه به نبود ضامن نقدشوندگی طی دوره (تا سررسید)، دسترسی به وجه سرمایه گذاری شده پیش از موعد سررسید و نقل و انتقال گواهی شراکت آن جز در صورت تجویز مقررات و با رعایت کلیه الزامات آنها، وجود نخواهد داشت . 
عامل در محدوده قوانین، مقررات و توافقات فیمابین، مسئول بررسی صحت اسناد و مدارک و ارائه خدمات مربوط به تامین مالی جمعی است. به همین دلیل، در راستای اجرای ماده ۳۲ «دستورالعمل تامین مالی جمعی» عامل توصیه به سرمایه گذاری در هیچ طرح خاصی نخواهد کرد. همچنین معرفی یا انتشار فراخوان طرح بدون اخذ نماد اختصاصی از فرابورس یا انجام اقدامات مربوط برای تامین مالی جمعی خارج از سکو، ممنوع و بلا اثر می باشد . 
تامین مالی جمعی در بستر قوانین و مقررات مربوط انجام شده و کلیه ذینفعان ملزم به اطلاع و رعایت آنها هستند. تغییرات قوانین و مقررات نافی علم و آگاهی سرمایه گذاران نسبت به آ نها نبوده و نظارت کارگروه ارزیابی تامین مالی جمعی بر فرایند مربوط، سالب مسئولیت تامین کنندگان در کسب آگاهی های مربوط و تعهد آنها به پذیرش ریسک های مطرح در سرمایه گذاری نخواهد بود. 
اعطای مجوز فعالیت به عامل توسط کارگروه ارزیابی تامین مالی جمعی و فرابورس ایران به منظور حصول اطمینان از رعایت قوانین و مقررات و شفافیت اطلاعاتی است. اعطای این مجوزها به معنی نظارت تمام عیار بر تمامی متقاضیان و تامین کنندگان یا تایید مزایا و تضمین سودآوری فعالیتهای آنها نبوده و هیچ یک از مجوزهای اعطا شده توسط فرابورس ایران مبنی بر تایید طرحی خاص یا توصیه به سرمایه گذاری در آن نیست. کارگروه ارزیابی تامین مالی جمعی و فرابورس ایران در خصوص ضرر و زیان ناشی از اتکا به تمام یا بخشی از مندرجات اسناد و مدارک مربوط، از خود سلب مسئولیت مینماید . 
اعلام ریسک های فوق به معنی سلب مسئولیت از عامل نبوده و از آنجا که عامل با اطلاع از قوانین و مقررات مربوط در حدود تکالیف مقرراتی، قراردادی و حرفه ای خود اقدام به فعالیت می نماید، در صورت قصور یا تقصیر از تکالیف مذکور، حسب مورد از حیث مدنی، کیفری یا انضباطی مسئول خواهد بود. به هر ترتیب، سرمایه گذاران میتوانند در صورت مشاهده هر گونه تخلف از مقررات حاکم بر تامین مالی جمعی مراتب را با فرابورس ایران مکاتبه نمایند. 
 
به موجب این سند، سرمایه گذار اقرارنامه و بیانیه ریسک سرمایه گذار ی در تامین مالی جمعی را دریافت و مطالعه نموده و ضمن اعلام اطلاع از کلیه قوانین، مقررات و خصوصیات اینگونه از سرمایه گذاری و ریسک هایی که در آن متصور می باشد ( از جمله و نه محدود به ریسک های مورد اشاره در این سند) مراتب را تایید نموده و با علم به آ نها در این زمینه فعالیت می نماید.`
        }
    },
    methods: {
        onclick() {
            setTimeout(() => this.show = false, 500)
        },
        onresult(obj) {
            if (this.base.Util().success({ data: obj }) && obj.data[0].channel === 'SADAD') {
                axios.get(`https://barmanazhandco.com/wp-content/plugins/sadadpsp/Send.php?token=${'fIGUfQYqiZLreMShKIUwzOPjd3Fg3xVl'}&amount=${obj.data[0].amount}&oid=${obj.data[0].id}&nid=${this.$cookies.get('username')}`)
                    .then((response) => {
                        if (response && response.data && response.data.ResCode === '0') {
                            window.location
                                .replace(`https://barmanazhandco.com/wp-content/plugins/sadadpsp/Redirect.php?TOKEN=${response.data.Token}`);
                        }
                    })
            } else if (this.base.Util().success({ data: obj })) {
                this.base.Notification().toast('نتیجه', 'عملیات ثبت با موفقیت انجام گردید.', 'success')
                this.dialog = false
                // window.close()
                setTimeout(() => {
                    this.$router.push('/Transaction/1/15')
                }, 2000)
            }
        }
    }
}
</script>

<style src="../assets/css/in.css"></style>