<template>
    <div class="content">
        <div class="header flex wrap center">
            <Header></Header>
            <div class="row">
                <div class="information flex wrap center">
                    <div class="flex wrap center vcenter">
                        <img class="icon flex center" src="../assets/images/wicon.png" />
                    </div>
                    <!-- <div class="flex wrap center vcenter">
                        <img src="../assets/images/watiyacrowd.png" />
                    </div> -->
                    <span>
                        سکوی تامین مالی جمعی آتیه ایرانیان یک پلتفرم تامین مالی جمعی تحت نهاد مالی مشاور
                        سرمایه گذاری فراز ایده نوآفرین تک (فاینتک) دارای مجوز از فرابورس ایران
                        به شماره مجوز به عنوان یک ابزار تامین مالی متعلق به شرکت بارمان آژند قرن ثبت شده نزد اداره ثبت
                        شرکتها
                        و موسسات غیرتجاری استان تهران به شماره ثبتی 570651 می باشد که با اتکا بر دانش و تجربه مدیران،
                        مشاوران و متخصصان حوزه کسب و کار و بازار پول و سرمایه به شناسایی طرح ها و پروژه های مولد و سودزا
                        و تامین سرمایه مورد نیاز آنها می نماید، شایان ذکر است این مجموعه با استفاده از ظرفیت های موجود
                        در کشور ضمن توجه ویژه به حوزه دانش بنیان و کسب و کار های نوپا در تلاش است بستری امن را برای
                        سرمایه گذاران و سرمایه پذیران فعالان حوزه اقتصادی فراهم نماید.
                    </span>
                    <button>کسب اطلاعات بیشتر، تشکیل پرونده و سرمایه گذاری</button>
                </div>
            </div>
        </div>
        <div class="introduction flex wrap center vcenter">
            <div class="row">
                <div class="head flex wrap center vcenter">
                </div>
                <div class="body flex wrap center vcenter">
                    <template v-for="(item, index) in introductions" :key="index">
                        <div class="flex wrap center vcenter"
                            :class="{ 'hidden': index > 4 && !introduction_expanded }">
                            <div class="flex wrap center vcenter"><img class="symbol" :src="item['image']" alt="" />
                            </div>
                            <h4 class="title">{{ item['title'] }}</h4>
                            <span class="description">{{ item['description'] }}</span>
                        </div>
                    </template>
                </div>
                <div class="footer flex wrap center vcenter">
                    <!-- <button @click.prevent="introduction_expanded = !introduction_expanded">{{ introduction_expanded ?
                        'نمایش کمتر' : 'نمایش بیشتر' }}</button> -->
                </div>
            </div>
        </div>
        <div class="reports flex wrap center vcenter">
            <div class="row">
                <div class="head">
                </div>
                <div class="body flex wrap center vcenter">
                    <template v-for="(report, index) in reports" :key="index">
                        <div class="flex wrap center vcenter">
                            <p class="number">{{ report['number'] }}</p>
                            <p class="title">{{ report['title'] }}</p>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="projects flex wrap center vcenter">
            <div class="row">
                <div class="head flex wrap center">
                    <div>
                        <h4>هم‌اکنون می‌توانید سرمایه گذاری کنید!</h4>
                    </div>
                </div>
                <div class="body flex wrap center vcenter">
                    <template v-for="(entity, index) in rows['entities']" :key="index">
                        <CardView :title="find(entity.id, 'folder_name')" :c_name="find(entity.id, 'company_name')"
                            :profit="find(entity.id, 'interest_rate')" :period="find(entity.id, 'period')"
                            :withdrawal="find(entity.id, 'dividend_period')" :symbols="find(entity.id, 'symobl')"
                            :id="entity.id">
                        </CardView>
                    </template>
                </div>
                <div class="footer flex wrap center vcenter">
                    <a class="flex wrap center vcenter" href="/Projects" target="_blank">
                        <button>مشاهده همه طرح‌ها</button>
                    </a>
                </div>
            </div>
        </div>
        <div id="services" class="services flex wrap center vcenter">
            <div class="row">
                <div class="head flex wrap center">
                </div>
                <div class="body flex wrap center vcenter">
                    <div class="right flex wrap center vcenter">
                        <video preload="none" poster="/videos/poster.png" controls>
                            <source src="/videos/intro.webm" type="video/webm; codecs=vp8.0">
                        </video>
                    </div>
                    <div class="left flex wrap center vcenter">
                        <div class="right flex wrap center vcenter">
                            <div class="step flex vend">
                                <div class="symbol flex wrap center vcenter">
                                    <img src="images/icons/ss_1.png" />
                                </div>
                                <div class="text">
                                    <h4>ثبت نام و تکمیل نمایه</h4>
                                </div>
                                <h4 class="stage">1</h4>
                            </div>
                            <div class="step flex vend">
                                <div class="symbol flex wrap center vcenter">
                                    <img src="images/icons/ss_2.png" />
                                </div>
                                <div class="text">
                                    <h4>انتخاب طرح و سرمایه گذاری</h4>
                                </div>
                                <h4 class="stage">2</h4>
                            </div>
                            <div class="step flex vend">
                                <div class="symbol flex wrap center vcenter">
                                    <img src="images/icons/ss_5.png" />
                                </div>
                                <div class="text">
                                    <h4>دریافت گواهی شراکت</h4>
                                </div>
                                <h4 class="stage">3</h4>
                            </div>
                        </div>
                        <div class="left flex wrap center vcenter">
                            <h4>تبریک! به خانواده <a href="https://atiyacrowd.ir">آتیا</a> پیوستید...</h4>
                            <img src="../assets/images/icon.png" />
                            <img class="confetti" src="/images/confetti.gif" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer flex wrap center vcenter">
                <div class="row">
                    <div class="head flex wrap center">
                        <div>
                            <h4>سرویس‌ها و خدمات</h4>
                        </div>
                    </div>
                    <div class="body flex wrap center vcenter">
                        <div class="service">
                            <div class="flex wrap center vcenter"><img src="/images/icons/s_1.png" /></div>
                            <h5>رزرو نوبت مشاوره</h5>
                            <p>رزرو نوبت مشاوره و مراجعه حضوری</p>
                        </div>
                        <div class="service">
                            <div class="flex wrap center vcenter"><img src="/images/icons/s_2.png" /></div>
                            <h5>همیشه و همه جا</h5>
                            <p>24 ساعت 7 روز هفته در خدمت شما</p>
                        </div>
                        <!-- <div class="service">
                            <div class="flex wrap center vcenter"><img src="/images/icons/s_3.png" /></div>
                            <h5>ماشین حساب بازدهی</h5>
                            <p>محاسبه سود سرمایه گذاری و مشارکت</p>
                        </div> -->
                        <div class="service">
                            <div class="flex wrap center vcenter"><img src="/images/icons/s_4.png" /></div>
                            <h5>استعلام گواهی شراکت</h5>
                            <p>صحت سنجی گواهی شراکت فرابورس</p>
                        </div>
                        <div class="service">
                            <div class="flex wrap center vcenter"><img src="/images/icons/s_5.png" /></div>
                            <h5>پرتال مشتریان</h5>
                            <p>سامانه آنلاین مشاهده و سرمایه گذاری</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="calculator flex wrap center">
            <div class="row">
                <div class="head flex wrap center">
                    <div>
                        <h4>ماشین حساب بازدهی</h4>
                    </div>
                </div>
                <div class="body flex wrap center vcenter">
                    <div>
                        <div class="flex wrap center vcenter">
                            <input type="text" placeholder="مبلغ سرمایه گذاری" v-model="models['calculator_value']" />
                            <p>{{ hint['calculator_value'] }}</p>
                        </div>
                        <div class="flex wrap center vcenter">
                            <button :class="{ 'selected': calculator_base === 'period' }"
                                @click.prevent="calculator_base = 'period'">طرح با تسویه سود به صورت دوره‌ای</button>
                            <button :class="{ 'selected': calculator_base === 'end' }"
                                @click.prevent="calculator_base = 'end'">طرح با تسویه سود در پایان قرارداد</button>
                        </div>
                        <div class="details flex wrap center vcenter">
                            <div class="flex wrap center vcenter">
                                <p>دوره قرارداد: N ماه</p>
                            </div>
                            <div class="flex wrap center vcenter">
                                <p>کل سود پیش بینی شده دوره: N درصد</p>
                            </div>
                            <div class="flex wrap center vcenter">
                                <p>دوره تسویه سود: N</p>
                            </div>
                            <div class="flex wrap center vcenter">
                                <p>سود پیش بینی شده هر دوره پروژه: N درصد</p>
                            </div>
                            <div class="flex wrap center vcenter">
                                <p>ضمانت سرمایه گذاری: N</p>
                            </div>
                            <div class="flex wrap center vcenter">
                                <p>N ریال سود پیش بینی شده حاصله از مشارکت</p>
                            </div>
                        </div>
                        <div class="flex wrap center vcenter">
                            <button class="invest">جهت سرمایه گذاری در طرح با مشخصات فوق همین الان اقدام
                                نمایید.</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="about flex wrap center vcenter">
            <div class="row">
                <div class="head">
                </div>
                <div class="body flex wrap center vcenter">
                    <div class="right">
                        <h4>درباره سکو</h4>
                        <p>
                            سکوی تامین مالی جمعی آتیه ایرانیان یک پلتفرم تامین مالی جمعی تحت نهاد مالی مشاور
                            سرمایه گذاری فراز ایده نوآفرین تک (فاینتک) دارای مجوز از فرابورس ایران
                            به شماره مجوز به عنوان یک ابزار تامین مالی متعلق به شرکت بارمان آژند قرن ثبت شده نزد اداره
                            ثبت
                            شرکتها
                            و موسسات غیرتجاری استان تهران به شماره ثبتی 570651 می باشد که با اتکا بر دانش و تجربه
                            مدیران،
                            مشاوران و متخصصان حوزه کسب و کار و بازار پول و سرمایه به شناسایی طرح ها و پروژه های مولد و
                            سودزا
                            و تامین سرمایه مورد نیاز آنها می نماید، شایان ذکر است این مجموعه با استفاده از ظرفیت های
                            موجود
                            در کشور ضمن توجه ویژه به حوزه دانش بنیان و کسب و کار های نوپا در تلاش است بستری امن را برای
                            سرمایه گذاران و سرمایه پذیران فعالان حوزه اقتصادی فراهم نماید.
                        </p>
                    </div>
                    <div class="left">
                        <h3>برای سرمایه گذاری‌های جدید خبرم کن</h3>
                        <div class="flex center vcenter">
                            <input type="text" placeholder="شماره همراه">
                            <button>عضویت در خبرنامه</button>
                        </div>
                        <Social></Social>
                    </div>
                </div>
            </div>
        </div>
        <div class="partners flex wrap center">
            <div class="row">
                <div class="head flex wrap center">
                    <div>
                        <h4>همکاران ما</h4>
                    </div>
                </div>
                <div class="body flex wrap center vcenter">
                    <div class="owl-carousel owl-theme">
                        <div class="item flex wrap center vcenter">
                            <img src="/images/refah.png" alt="">
                        </div>
                        <div class="item flex wrap center vcenter">
                            <img src="/images/sahand.png" alt="">
                        </div>
                        <div class="item flex wrap center vcenter">
                            <img src="/images/tejaratpaydar.png" alt="">
                        </div>
                        <div class="item flex wrap center vcenter">
                            <img src="/images/meli.png" alt="">
                        </div>
                        <div class="item flex wrap center vcenter">
                            <img src="/images/pasargad.png" alt="">
                        </div>
                        <div class="item flex wrap center vcenter">
                            <img src="/images/sarafraz.png" alt="">
                        </div>
                        <div class="item flex wrap center vcenter">
                            <img src="/images/tehran.png" alt="">
                        </div>
                        <div class="item flex wrap center vcenter">
                            <img src="/images/noavari.png" alt="">
                        </div>
                        <div class="item flex wrap center vcenter">
                            <img src="/images/etemad.png" alt="">
                        </div>
                        <div class="item flex wrap center vcenter">
                            <img src="/images/saderat.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
/* eslint-disable */
import Header from '../components/Header.vue'
import CardView from '../components/CardView.vue'
import Social from '../components/Social.vue'
import Footer from '../components/Footer.vue'

require('../assets/js/amount')
require('../assets/js/owl.carousel.min')

export default {
    name: 'Home',
    inject: ['base'],
    components: { Header, CardView, Social, Footer },
    mounted() {
        let description = document.createElement('meta')
        description.setAttribute('name', 'description')
        description.setAttribute('content', 'آتیا کراد، پلتفرم تامین مالی جمعی دارای مجوز و مبتنی بر ضمانتنامه معتبر بانکی جهت سرمایه گذاری و تامین مالی پروژه ها و کسب و کارهای صنایع مختلف')
        document.head.appendChild(description)

        $(document).ready(function () {
            $('.owl-carousel').owlCarousel({
                startPosition: 0,
                loop: true,
                autoplay: true,
                autoplaySpeed: 4000,
                items: 1,
                margin: 1,
                rtl: true,
                nav: false,
                touchDrag: true,
                autoHeight: true,
                animateOut: 'fadeOut',
                dots: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    400: {
                        items: 2
                    },
                    500: {
                        items: 3
                    },
                    700: {
                        items: 4
                    },
                    1000: {
                        items: 6
                    }
                    ,
                    1200: {
                        items: 7
                    }
                }
            });
        })
    },
    created() {
        this.base.API().get('Identities/Folder/FList'
            , (response) => {
                if (this.base.Util().success(response)) {
                    this.rows['entities'] = response.data.entities
                    this.rows['values'] = response.data.data
                }
            }, (error) => {

            })
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            models: {},
            hint: {},
            introductions: [
                {
                    title: 'مطابق با عقود اسلامی',
                    description: 'مورد استفتاء از دفاتر مراجع عظام تقلید و تحت نظر شورای فقه اقتصادی',
                    image: '/images/icons/i_2.png'
                },
                {
                    title: 'قانونی و دارای مجوز',
                    description: 'تحت نظارت و دارای مجوز از فرابورس ایران',
                    image: '/images/icons/i_13.png'
                },
                {
                    title: 'قابل دسترس',
                    description: 'همیشه و همه جا به پرونده های خود دسترسی دارید',
                    image: '/images/icons/i_8.png'
                },
                {
                    title: 'اطلاع رسانی سریع',
                    description: 'با بیش از ۱۰ پل ارتباطی همواره در جزئیات اخبار و اطلاعیه ها خواهید بود',
                    image: '/images/icons/i_5.png'
                },
                {
                    title: 'مسلط و دارای سابقه درخشان',
                    description: 'دارای ۱۵ سال سابقه کار مالی و تامین مالی در حوزه های مختلف',
                    image: '/images/icons/i_3.png'
                },
                {
                    title: 'مولد و تولید محور',
                    description: 'سرمایه گذاری و گزینش پروژه های تولید محور جهت تجهیز سرمایه',
                    image: '/images/icons/i_19.png'
                },
                {
                    title: 'دارای اپلیکیشن موبایل',
                    description: 'دارای نرم افزار اندروید و آی او اس جهت دسترسی به آتیا در همه جا',
                    image: '/images/icons/i_18.png'
                },
                {
                    title: 'شفاف و شیشه‌ای',
                    description: 'ارائه گزارشات منظم و شفاف دوره ای سه، شش ماهه و یکساله',
                    image: '/images/icons/i_7.png'
                },
                {
                    title: 'مبتکر و نوآورانه',
                    description: 'استفاده از ایده ها و الگو های مبتکرانه، نوآورانه و فناورانه',
                    image: '/images/icons/i_15.png'
                },
                {
                    title: 'پشتیبانی ۷ در ۲۴',
                    description: 'مرکز تعامل با مشتریان آتیا ۷ روز هفته ۲۴ ساعته آماده خدمت رسانی',
                    image: '/images/icons/i_4.png'
                },
                {
                    title: 'همکاری و درآمد پایدار',
                    description: 'مشارکت و عضویت در تیم بازاریابی و کسب درآمد پایدار',
                    image: '/images/icons/i_21.png'
                },
                {
                    title: 'ماشین حساب محاسبه سود',
                    description: 'سیستم محاسبه سود و آورده با استفاده از ماشین حساب',
                    image: '/images/icons/i_10.png'
                },
                {
                    title: 'هدایا و امتیازات ویژه',
                    description: 'حق المعرف، پاداش های دوره ای از محل سود مازاد و انباشته',
                    image: '/images/icons/i_14.png'
                },
                {
                    title: 'تنوع مشتریان',
                    description: 'دارای تنوع مشتریان از جمله اشخاص حقیقی و حقوقی خصوصی و دولتی',
                    image: '/images/icons/i_8.png'
                },
                {
                    title: 'کادر اداری و اجرایی کارآمد',
                    description: 'عضو ۲۷ نفره متشکل از مدیران و کارشناسان جوان و متخصص',
                    image: '/images/icons/i_11.png'
                },
                {
                    title: 'مشاوره آنلاین افراد و مشاغل',
                    description: 'ارائه مشاوره سرمایه گذاری تخصصی به صورت آنلاین و حضوری',
                    image: '/images/icons/i_6.png'
                }
            ],
            introduction_expanded: false,
            reports: [
                {
                    number: 0,
                    title: 'پروژۀ موفق'
                },
                {
                    number: 0,
                    title: 'میلیارد تومان سرمایه گذاری'
                },
                {
                    number: 0,
                    title: 'میلیارد تومان سود تسویه شده'
                },
                {
                    number: 0,
                    title: 'مشارکت کنندگان'
                },
                {
                    number: 0,
                    title: 'کارآفرین'
                }
            ],
            calculator_base: '',
            rows: { entities: [], values: [] }
        }
    },
    watch: {
        models: {
            handler(oldModels, newModels) {
                Object.keys(newModels).forEach((key) => {
                    this.models[key] = newModels[key].replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    this.hint[key] = newModels[key].replace(/,/g, '').num2persian() + ' ریال'
                })
            },
            deep: true
        }
    },
    methods: {
        find(id, key) {
            return this.rows.values.find((value) =>
                Number(value.parent_id
                    .replace('AC', '').replace('GR', '')
                    .replace('RO', '').replace('FO', '')
                    .replace('FI', '').replace('VV', '')) === id && value.key === key)?.value || ''
        }
    }
}
</script>

<style scoped src="../assets/css/home.css"></style>
<style src="../assets/css/owl.theme.default.min.css"></style>
<style src="../assets/css/owl.carousel.min.css"></style>